@font-face {
  font-family: 'Inter Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Inter Regular'), url('fonts/Inter-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Inter Light';
  font-style: normal;
  font-weight: normal;
  src: local('Inter Light'), url('fonts/Inter-Light.ttf') format('woff');
}


@font-face {
  font-family: 'Inter Semi Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Inter Semi Bold'), url('fonts/Inter-SemiBold.woff') format('woff');
}


@font-face {
  font-family: 'Inter Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Inter Bold'), url('fonts/Inter-Bold.woff') format('woff');
}

body {
  font-family: sans-serif;
  margin: 0;
  // overflow: hidden;
  background-color: #030B33;
}

#container,
#css_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#smooth-content {
  height: 1800vh;
}

#percentage {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 100;
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: 0;
  //display:none !important;
}

#guide {
  position: fixed;
  bottom: 10px;
  left: 20px;
  z-index: 100;
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

div {
  color: white !important;
  font-family: sans-serif;
}

body {
  margin: 0px;
  font-family: monospace;
  color: white;
}

canvas,
#container,
#css_container {
  position: fixed;
  top: 0;
  left: 0;
}

#scroll_container {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  scroll-behavior: smooth;
  z-index: 99;
  position: absolute;
}

#scroll-icon {
  transition: opacity 0.5s ease;
}

#footer-links {
  transition: opacity 1s ease;
}

section {
  min-height: 1800vh;
  height: 1800vh;
}

#scrollProgress {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 99;
  font-size: 3vh;
}

#percent_bar {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  display: none;
}


.navigation {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  z-index: 9;

  @media(min-width: 768px) {
    right: 10px;
  }

  .item {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 20px 0;
    position: relative;

    .label {
      margin-right: 15px;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 100%;
      font-weight: bold;
      font-size: 12px;
      white-space: nowrap;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    .filler {
      display: none;
    }

    .bar {
      width: 4px;
      height: 4px;
      background-color: rgba(255, 255, 255, 0.3);
      margin: 0 10px;
      overflow-x: hidden;
      position: relative;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    .number {
      display: none;
      font-size: 12px;

      @media(min-width: 1025px) {
        display: block;
      }

      &.before {
        opacity: 0.3;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      &.after {
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }

    &:not(.active):hover {
      cursor: pointer;

      .filler {
        display: block;
      }

      @media(min-width: 1025px) {
        .label {
          opacity: 1;
          visibility: visible;
        }
      }

      .bar {
        background-color: rgba(255, 255, 255, 1);
      }

      .number {
        &.before {
          opacity: 1;
        }
      }
    }

    &.active {
      .number {
        &.before {
          opacity: 0;
        }

        &.after {
          opacity: 1;
        }
      }

      .bar {
        height: 60px;

        @media(min-width: 1025px) {
          height: 170px;
        }

        .filler {
          background-color: #FFF;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          display: block;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
      }

      &.sections-1 {
        .bar {
          .filler {
            top: 0;
            height: 100%;
          }
        }
      }

      &.sections-2 {
        .bar {
          .filler {
            top: 0;
            height: 50%;
          }
        }

        &.section-2-active {
          .bar {
            .filler {
              top: 0;
              height: 100%;
            }
          }
        }
      }

      &.sections-4 {
        .bar {
          .filler {
            top: 0;
            height: 25%;
          }
        }

        &.section-2-active {
          .bar {
            .filler {
              top: 0;
              height: 50%;
            }
          }
        }

        &.section-3-active {
          .bar {
            .filler {
              top: 0;
              height: 75%;
            }
          }
        }

        &.section-4-active {
          .bar {
            .filler {
              top: 0%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.bottom-left {
  position: fixed;
  z-index: 1;
  bottom: 30px;
  left: 10px;

  @media(min-width: 768px) {
    left: 20px;
    bottom: 20px;
  }

  svg {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .drop-gradient {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    width: 1px;
    margin-left: 9px;
    height: 100px;
  }

  .bottom {
    display: flex;
    align-items: center;
    flex-direction: row;

    span,
    a {
      font-size: 10px;
      font-family: "Inter Bold";
      margin-right: 20px;
      display: inline-block;
      color: #FFF;
      text-decoration: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

#footer-links {
  opacity: 0;
}

body.show-scroll {
  #guide {
    opacity: 1;
  }
}

.panel-label-container {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  .label {
    text-align: center;
    font-size: 14px;
  }
}

#scroll-icon {
  margin-bottom: -40px;

  @media(min-width: 768px) {
    margin-bottom: -20px;
  }
}

.overlay {
  & div {
    color: black !important;
  }
}

.cursor-dot-outline {
  opacity: 1;
  width: 6px;
  height: 6px;
  background: #E6263D;
  transform: translate(100px, 100px);
  position: fixed;
  z-index: 10001;
  cursor: none;
}